import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import './App.css';
import { DataProvider } from './component/context';
import { CoursePage } from './pages/class';
import { EventPage } from './pages/events';
import { HomePage } from './pages/home';
import { MentorDetail } from './pages/mentor';
import { Profile } from './pages/profile';
import { StartedPage } from './pages/started';

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartedPage />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (profile) {
        return redirect("/home");
      }
      return null;
    }
  },
  {
    path: "/home",
    element: <HomePage />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (!profile) {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/mentor/:id",
    element: <MentorDetail />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (!profile) {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/courses/:id",
    element: <CoursePage />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (!profile) {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/events/:id",
    element: <EventPage />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (!profile) {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/profile",
    element: <Profile />,
    loader: () => {
      const profile = localStorage.getItem("profile")
      if (!profile) {
        return redirect("/");
      }
      return null;
    }
  }
])

function App() {
  return (
    <DataProvider>
      <RouterProvider router={router} />
    </DataProvider>
  );
}

export default App;
