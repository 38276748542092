import { CaretLeft } from "@phosphor-icons/react";
import { CoinVertical } from "@phosphor-icons/react/dist/ssr";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ModalComponent } from "../../component/modal";
import { AppWrapper } from "../../global/style";
import { Attendance } from "../../modal/attendance";
import { sendSlackNotification } from "../../services/slack";
import { BottomSheet, BottomSheetAttendance, BottomSheetContainer, CourseBody, CourseWrapper } from "../class/style";
import { MentorHeaderIcon } from "../mentor/style";
import { EventData } from "./data";

export const SLACK_EVENT_WEBHOOK = 'https://hooks.slack.com/services/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';

const EventHeader = styled.div`
    position: relative;

    & > img {
            width: 100%;
        object-fit: cover;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const BackWrapper = styled.div`
    position: absolute;
    left: 10px;
    top: 10px;
`;

export const EventPage = () => {
    const { id } = useParams();
    const [obj, setObj] = useState({});
    const [user, setUser] = useState({});
    const [isJoining, setIsJoining] = useState(false);
    const [isRedeemed, setIsRedeemed] = useState(false);
    const navigate = useNavigate();
    const [attend, setAttend] = useState(false);

    const fetchEvent = () => {
        const course = EventData.find(item => item.id === Number(id));
        const profile = localStorage.getItem("profile");
        setObj(course);
        let userProfile = JSON.parse(profile);
        setUser(userProfile);
        let exists = userProfile.events.find(item => item.id === Number(id));
        let redeemed = userProfile.events.find(item => item.id === Number(id) && item.is_redeemed === 1)
        if (exists) {
            setIsJoining(true);
        }

        if (redeemed) {
            setIsRedeemed(true);
        }
    }

    useEffect(() => {
        fetchEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppWrapper>
            <EventHeader>
                <BackWrapper>
                    <MentorHeaderIcon>
                        <CaretLeft size={15} onClick={() => navigate("/")} />
                    </MentorHeaderIcon>
                </BackWrapper>
                <img src={obj.image} alt={obj.name} />
            </EventHeader>
            <CourseWrapper>
                <h1>{obj.name}</h1>
                <CourseBody>
                    {obj.description && parse(obj.description)}
                </CourseBody>
            </CourseWrapper>
            <BottomSheet>
                <BottomSheetContainer>
                    {isJoining ? <BottomSheetAttendance>
                        {isRedeemed ? <p><CoinVertical size={20} color="#fec204" /> You get {obj.point} points!</p> : <p>Redeem to get {obj.point} points</p>}
                        {isRedeemed ? <span>Attended!</span> : <span onClick={() => setAttend(true)}>Attendance</span>}
                    </BottomSheetAttendance> : <span onClick={() => {
                        const response = window.confirm(`Are you sure you want to join ${obj.name}?`);
                        if (response) {
                            let newObj = obj;
                            newObj.is_redeemed = 0;
                            user.events.push(newObj);
                            localStorage.setItem("profile", JSON.stringify(user));
                            setIsJoining(true);
                            let message = `*Event Member*\n\n*Name*\n${user.name}\n*Campus*\n${user.campus}\n*Phone*\n${user.phone}\n*Event*\n${newObj.name}`
                            sendSlackNotification(message, SLACK_EVENT_WEBHOOK);
                        }
                    }}>Join</span>}
                </BottomSheetContainer>
            </BottomSheet>
            <ModalComponent
                show={attend}
                onHide={() => setAttend(!attend)}
                body={<Attendance name={user.name} classname={obj.name} code={obj.code} point={obj.point} id={obj.id} type={'events'} />}
            />
        </AppWrapper>
    )
}