import React, { useState } from "react";

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({});

    const setFormData = (form) => {
        setData(form);
    }

    return (
        <DataContext.Provider
            value={{
                data,
                setFormData,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}