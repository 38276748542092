import styled from "styled-components";

export const CourseHeader = styled.div`
    & > img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
    }
`;

export const CourseWrapper = styled.div`
    padding: 1rem 1.5rem 4.7rem;

    & > h1 {
        font-size: 27px;
        font-weight: 700;
        line-height: 1.3;
    }
`;

export const MentorFlex = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
`;

export const MentorDetailFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    & > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border: 1px solid #ddd;
        border-radius: 50%;
    }

    & > span {
        font-weight: 600;
    }

    & > p {
        font-size: 15px;
    }
`;

export const CourseBody = styled.div`
    margin: 1rem 0;
    transition: .4s ease;

    & > p {
        margin-bottom: 8px;
        transition: .5s ease;
    }

    & > span {
        color: #3d4cdf;
        font-weight: 600;
    }
`;

export const BottomSheet = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
`;

export const BottomSheetAttendance = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        background-color: #3d4cdf;
        padding: 6px 18px;
        border-radius: 3px;
        font-size: 16px;
        color: #fff;
        border: 0;
        font-weight: 600;
    }

    & > p {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export const BottomSheetContainer = styled.div`
    padding: 1.5rem 1.5rem;

    & > span {
        background-color: #3d4cdf;
        padding: 6px 18px;
        border-radius: 3px;
        font-size: 16px;
        color: #fff;
        border: 0;
        font-weight: 600;
    }
`;