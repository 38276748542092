import abba from '../../assets/image/company/abba.png';
import acca from '../../assets/image/company/acca.png';
import apple from '../../assets/image/company/apple.png';
import binus from '../../assets/image/company/binus.png';
import citi from '../../assets/image/company/citi.png';
import coolkas from '../../assets/image/company/coolkas.png';
import djp from '../../assets/image/company/djp.png';
import ey from '../../assets/image/company/ey.png';
import gfk from '../../assets/image/company/gfk.png';
import inkaya from '../../assets/image/company/inkaya.png';
import internnet from '../../assets/image/company/internnet.png';
import intrn from '../../assets/image/company/intrn.png';
import kibar from '../../assets/image/company/kibar.png';
import local from '../../assets/image/company/local.png';
import madera from '../../assets/image/company/madera.jpeg';
import pcu from '../../assets/image/company/pcu.png';
import phoenix from '../../assets/image/company/phoenix.png';
import pilarbangsa from '../../assets/image/company/pilar bangsa.png';
import prasmul from '../../assets/image/company/prasmul.png';
import shopee from '../../assets/image/company/shopee.png';
import st from '../../assets/image/company/st.png';
import sbit from '../../assets/image/company/stockbit.png';
import succor from '../../assets/image/company/succor.png';
import tfi from '../../assets/image/company/tfi.png';
import trust from '../../assets/image/company/trust.png';
import uc from '../../assets/image/company/uc.png';
import unpad from '../../assets/image/company/unpad.png';
import unri from '../../assets/image/company/unri.png';
import uph from '../../assets/image/company/uph.png';
import wf from '../../assets/image/company/wf.png';


export const MentorData = [
    {
        "id": 8,
        "name": "Oktavianus",
        "full_name": "Timotius Oktavianus",
        "about": "Graduated from Binus with Majored in Computer Science, IPK 3.67. After that Join Fulltime Training Indonesia for 2 years. Been serving the young people for almost 8 years and held Bible mentorship to college students.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/12_ouia3i.png',
        "speciality": "Bible Study, Character Development, Leadership",
        "experiences": [
            {
                "company": "TRUST Indonesia",
                "title": "Student Counselor",
                "period": "2017 - Now",
                "location": "Jakarta, Pekanbaru",
                "description": `<ul><li>Helping students to know their purpose of life through Christian Educations</li><li>Performing 1on1 mentorship</li></ul>`,
                "image": trust
            },
            {
                "company": "Universitas Riau",
                "title": "Dosen Agama Kristen",
                "period": "2019 - 2021",
                "location": "Pekanbaru",
                "description": `<ul><li>Creating curriculum for Christian Classes</li><li>Teaching classes with basic faith of Christianity</li></ul>`,
                "image": unri
            },
        ],
        "educations": [
            {
                "campus": "Binus University",
                "year": "2011 - Jan 2015",
                "major": "Computer Science",
                "gpa": "3.67/4.0",
                "image": binus
            }
        ],
        "portofolio": [
            {
                "name": "Instagram",
                "url": "https://instagram.com/oktavian2913"
            },
        ]
    },
    {
        "id": 1,
        "name": "Kevin",
        "full_name": "Kevin Christian",
        "about": "A dedicated software engineer with 4 years of experience in developing scalable solutions. Beyond my professional work, I educate and inspire thousands through my tech content on TikTok and Instagram, making complex programming concepts accessible and engaging for a broad audience.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019469/trust/mentors/8_ecke23.png',
        "speciality": "Software Development, Leadership, Networking, Industry Insights, Career Planning",
        "experiences": [
            {
                "company": "INTRN",
                "title": "Founder",
                "period": "June 2024 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Led product development, resulting in the successful launch of a new product line</li><li>Managed to create INTRN web apps from scratch and get 200 web visitors from the first launch.</li></ul>`,
                "image": intrn
            },
            {
                "company": "Coolkas",
                "title": "Founding Engineer",
                "period": "May 2023 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Elevating UI/UX, leading to higher worker and client satisfaction </li><li>Design and build from scratch a simplified system to accomodate Document Generator, Inventory Management, Product Management</li><li>Build from scratch both Dashboard and Backend to accommodate the needs of client</li><li>Perform quick development on Driver and Client Dashboard web apps within 2 days</li></ul>`,
                "image": coolkas
            },
            {
                "company": "Stockbit",
                "title": "Software Engineer",
                "period": "January 2022 - April 2023",
                "location": "Jakarta",
                "description": `<ul><li>Assigned to Social and Education Squad, developing and maintaining Stream services, and some others such as Live Stream, Academy</li><li>Helping Trading Squad on handling Gojek IPO</li><li>Migrating Target Price and implement better flow using Kafka</li></ul>`,
                "image": sbit
            },
            {
                "company": "Abbasource",
                "title": "Tech Lead",
                "period": "Nov 2020 - January 2022",
                "location": "Jakarta",
                "description": `<ul><li>Build from scratch the whole Abbasource system using React, Flutter and Go</li><li>Lead the team to delivery Abbasource Core Products</li><li>Managed to increase sales partner revenue and members by releasing ABuddy apps</li></ul>`,
                "image": abba
            },
            {
                "company": "Style Theory",
                "title": "Software Engineer",
                "period": "Oct 2019 - Oct 2020",
                "location": "Jakarta",
                "description": `<ul><li>Developing On Demand Rental</li><li>Assigned to Handle Discover Services such as Inventory and migrate services to Golang</li></ul>`,
                "image": st
            },
            {
                "company": "Shopee",
                "title": "Backend Engineer",
                "period": "Aug - Oct 2019",
                "location": "Jakarta",
                "description": `<ul><li>Developing Shopee Games (Tebak Juara)</li></ul>`,
                "image": shopee
            }
        ],
        "educations": [
            {
                "campus": "Binus",
                "year": "2013-2017",
                "major": "Computer Science",
                "gpa": "3.65/4.00",
                "image": binus
            }
        ],
        "portofolio": [
            {
                "name": "Web",
                "url": "https://bykace.com"
            },
            {
                "name": "Instagram",
                "url": "https://instagram.com/kvynchristian"
            },
            {
                "name": "Linkedin",
                "url": "https://www.linkedin.com/in/nkevinch/"
            },
            {
                "name": "Tiktok",
                "url": "https://www.tiktok.com/@katdje"
            }
        ]
    },

    {
        "id": 5,
        "name": "Yehezkiel",
        "full_name": "Yehezkiel Valentino",
        "about": "I am highly motivated, enthusiastic, and dynamic final year accounting student. Has a positive outlook on life, enjoys learning new things, and eager in finding solutions to existing situation with a background concentration in Taxation. where I aim to make a meaningful impact. With strong teamwork skills and a curious mindset, I drive projects forward.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019470/trust/mentors/7_jofiub.png',
        "speciality": "Tax, Accounting, Teaching",
        "experiences": [
            {
                "company": "EY",
                "title": "Tax Intern",
                "period": "Feb 2024 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Assist with a comprehensive range of tax-related tasks</li><li>Ensuring meticulous preparation and filing of tax objections and appeals</li><li>Analyzing the tax implications of corporate transactions</li><li>Drafting precise appointment letters for tax audits</li><li></li>Executing the annual filing of individual income tax returns, including Form 1770 and 1770S</ul>`,
                "image": ey
            },
            {
                "company": "Internnet Indonesia",
                "title": "Mentor",
                "period": "2024",
                "location": "Jakarta",
                "description": `<ul><li>Providing informations, tips & tricks about consultant and career vibes in consulting company</li></ul>`,
                "image": internnet
            },
            {
                "company": "ACCA",
                "title": "Student Ambassador",
                "period": "2023",
                "location": "Jakarta",
                "description": `<ul><li>Apply effective communication skills when interacting with external parties, particularly as a key point of contact for LSAF, ACCA, and BINUS university</li><li>Coordinate and collaborate on organizing a series of international webinar events</li><li>Take on the role of master of ceremonies and host during international webinar events</li></ul>`,
                "image": acca
            },
            {
                "company": "Direktorat Jenderal Pajak (DJP)",
                "title": "Tax Volunteer",
                "period": "2022",
                "location": "Jakarta",
                "description": `<ul><li>Assist the Direktorat Jenderal Pajak (KPP Kebon Jeruk 1) in filling out and reporting Personal Income Tax Returns (SPT Orang Pribadi)</li></ul>`,
                "image": djp
            },
            {
                "company": "Binus",
                "title": "Accounting and Finance Laboratory Assistant",
                "period": "2022 - 2023",
                "location": "Jakarta",
                "description": `<ul><li>Undertaking the role of teaching and mentoring over 50 fellow students in a variety courses</li><li>Encompassing tax, accounting, and audits to contribute to the students academic and professional development</li></ul>`,
                "image": binus
            },
        ],
        "educations": [
            {
                "campus": "Binus University",
                "year": "2021 - Now",
                "major": "Accounting",
                "gpa": "3.74/4.0",
                "image": binus
            }
        ],
        "portofolio": [
            {
                "name": "Linkedin",
                "url": "https://www.linkedin.com/in/nkevinch/"
            },
            {
                "name": "Instagram",
                "url": "https://instagram.com/yehezzkiel_valentino"
            },
        ]
    },
    {
        "id": 6,
        "name": "Reinhard",
        "full_name": "Reinhard Suryanaga",
        "about": "I specialize in generating new customers and sales in the financial industry. Deep Knowledge in Investment and wealth management and also the investor. Experienced Handling 70+ High net worth clients and Managing a Portfolio of approximately 150 Bio. Eager to learn new skills in digital and development in the Financial sector and Passionate about education technology to make education accessible to everyone.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/11_grkxjc.png',
        "speciality": "Investment, Wealth Management, Education Tech",
        "experiences": [
            {
                "company": "Succor Asset Management",
                "title": "Bank Distribution Sales",
                "period": "Dec 2023 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Update market to Client</li><li>Maintain Relationship with Banks Partner</li><li>Increase revenue and AUM</li></ul>`,
                "image": succor
            },
            {
                "company": "Citi",
                "title": "Funding Acquisition Specialist Citigold",
                "period": "Jul 2021 - Nov 2023",
                "location": "Jakarta",
                "description": `<ul><li>Service existing accounts and establish new accounts at a designated location or assigned geographical coverage area to increase revenue for the bank by approximately 6.5 Bio per month.</li>Proactively approach Citi clients (High net Worth) to establish real needs, understand the client and their requirements, and interest them in Citi’s products.<li> Provide advice and product information to Citi clients (High Net Worth) as appropriate, outlining features and benefits according to their risk.</li><li>Fully Certified as a Consultant (Waperd, AAJI, & WPPE) in 2 months</li><li>Achieve or exceed decided sales targets of 3-5 NTB/months</li><li>Best CGAT of the Month at May - June 2022</li></ul>`,
                "image": citi
            },
            {
                "company": "Abbasource",
                "title": "Sales Business Development",
                "period": "Jan 2021 - May 2021",
                "location": "Jakarta",
                "description": `<ul><li>Reach out new clients</li><li>Building new partnership with 200 partner in 3 months</li><li>Maintain existing customer relationship</li></ul>`,
                "image": abba
            },
            {
                "company": "Kibar",
                "title": "Project Management Intern",
                "period": "Feb - Aug 2017",
                "location": "Jakarta",
                "description": `<ul><li>handle the projects events with 500 participants such as Youtube space events, geekfest 2017, Gerakan nasional 1000 startup digital, etc.</li><li>Build a relationship with Community Partner</li></ul>`,
                "image": kibar
            },
            {
                "company": "Teach for Indonesia",
                "title": "Mentor",
                "period": "Nov - Dec 2015",
                "location": "Jakarta",
                "description": `<ul><li>Teach younger generation in primary school called (PAUD).</li></ul>`,
                "image": tfi
            },
        ],
        "educations": [
            {
                "campus": "Binus University",
                "year": "2015 - 2018",
                "major": "International Business Management",
                "gpa": "3.8/4.0",
                "image": binus
            }
        ],
        "portofolio": [
            {
                "name": "Linkedin",
                "url": "https://www.linkedin.com/in/reinhardsuryanaga"
            },
            {
                "name": "Instagram",
                "url": "https://instagram.com/reinhardsuryanaga"
            },
        ]
    },
    {
        "id": 9,
        "name": "Andrew",
        "full_name": "Prudence Andrew",
        "about": "Passionate about product development rooted in thorough research and measurable data, I am Prudence Andrew. My relentless pursuit of knowledge drives me to never cease learning, constantly seeking reliable sources to expand my expertise. Committed to honing my skills, I've participated in a boot camp program to learn about Product Manager and Data Analyst. Adding to my journey, I am proud to be part of the Apple Developer Academy @ UC 2023, where I delve into the innovative world of app development, and gained more experience as a Project Manager and Product Manager.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019467/trust/mentors/15_qwbu3n.png',
        "speciality": "Project Management, Data Analysis, Product Management, Market Research",
        "experiences": [
            {
                "company": "Gfk",
                "title": "Research Executive",
                "period": "Jul 2023 - Now",
                "location": "Jakarta",
                "description": ``,
                "image": gfk
            },
            {
                "company": "Inkaya Pangan Indonesia",
                "title": "Owner",
                "period": "Jan 2021 - Now",
                "location": "Surabaya",
                "description": `<ul><li>Doing Market research to formulate the organization's strategy concerning operational, product, and marketing.</li><li>Making a technical plan to execute the strategic.</li><li>Develop the product and service by doing a market survey</li><li>Coordinate the team as the Team Leader</li></ul>`,
                "image": inkaya
            },
            {
                "company": "Apple Developer Academy",
                "title": "Product Manager Intern",
                "period": "Mar 2023 - Dec 2023",
                "location": "Surabaya",
                "description": `<ul><li>Do the research, so that the application not only finished but also will be useful to the user.</li><li>making a PRD and user flow.</li><li>produced at least 4 iOS applications (Pathways, Unexposed, Stride, Disén.In).</li></ul>`,
                "image": apple
            },
        ],
        "educations": [
            {
                "campus": "Universitas Ciputra",
                "year": "Aug 2012 - Jul 2017",
                "major": "Master of Management, Business Family and Consumer Science/Human Sciences",
                "gpa": "3.85/4.0",
                "image": uc
            },
            {
                "campus": "Universitas Padjadjaran",
                "year": "Aug 2012 - Jul 2017",
                "major": "Teknologi Pangan",
                "gpa": "3.01/4.0",
                "image": unpad
            }
        ],
        "portofolio": [
            {
                "name": "Web",
                "url": "https://prudenceandrew.com"
            },
            {
                "name": "Instagram",
                "url": "https://instagram.com/prudenceandrew"
            },
        ]
    },
    {
        "id": 10,
        "name": "Richard",
        "full_name": "Richard Nathaniel",
        "about": "Master of Management from Universitas Prasetiya Mulya. Graduated from the Founder Institute. Currently building Hewania, vet clinic & digital animal healthcare ecosystem. Speciality in Startup Business & Pet Business.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019466/trust/mentors/16_hfmzxg.png',
        "speciality": "Leadership, Business Development, Product Management, Marketing",
        "experiences": [
            {
                "company": "Hewania",
                "title": "CEO & Founder",
                "period": "2021 - Now",
                "location": "Jakarta",
                "description": ``,
                "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723016115/trust/6c35ad79-2280-4ce7-be6c-c2692c0806d6.png'
            },
            {
                "company": "Jakmall",
                "title": "Product Manager",
                "period": "Jan 2021",
                "location": "Jakarta",
                "description": `<ul><li>Doing Market research to formulate the organization's strategy concerning operational, product, and marketing.</li><li>Making a technical plan to execute the strategic.</li><li>Develop the product and service by doing a market survey</li><li>Coordinate the team as the Team Leader</li></ul>`,
                "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723016959/trust/beea7a10-0e5c-4cdd-bd05-61a2d13c0c3c.png'
            },
            {
                "company": "Sunchilla",
                "title": "System Engineer & Product Manager",
                "period": "2020",
                "location": "Jakarta",
                "description": `<ul><li>Do the research, so that the application not only finished but also will be useful to the user.</li><li>making a PRD and user flow.</li><li>produced at least 4 iOS applications (Pathways, Unexposed, Stride, Disén.In).</li></ul>`,
                "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723017080/trust/9715ef8e-e6cd-4e4c-9f1a-c7a0d7802087.png'
            },
        ],
        "educations": [
            {
                "campus": "Universitas Prasetiya Mulya",
                "year": "Oct 2021 - Aug 2023",
                "major": "Master of Management, New Ventures Innovation",
                "gpa": "3.5/4.0",
                "image": prasmul
            },
            {
                "campus": "Surya University",
                "year": "2014 - 2018",
                "major": "Computer Science",
                "gpa": "3.53/4.0",
                "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723018075/trust/214007da-f642-4a83-8ead-e9d10d58a2dc.png'
            }
        ],
        "portofolio": [
            {
                "name": "Linkedin",
                "url": "https://www.linkedin.com/in/richardnc96"
            },
            {
                "name": "Instagram",
                "url": "https://www.instagram.com/richardnc96"
            },
        ]
    },
    {
        "id": 2,
        "name": "Leo",
        "full_name": "Leo Silaban",
        "about": "I am a graduate of Ciputra University, Surabaya. I majored in Psychology, and have experience working in the last 3 years as a Counselor at Citra Berkat Ciputra High School, head of employee training. Currently I am actively serving full time in the Church and campus youth in Binus.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019467/trust/mentors/13_hh7fyv.png',
        "speciality": "Counselling, Research",
        "experiences": [
            {
                "company": "TRUST Jakarta",
                "title": "Youth Counselor",
                "period": "January 2024 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Arranging Program to Help students excel on their campus</li><li>Do 1on1 mentorship with campus students</li></ul>`,
                "image": trust
            },
            {
                "company": "Ciputra University",
                "title": "101 Training Mentors",
                "period": "2020 - 2021",
                "location": "Surabaya",
                "description": `<ul><li>Organizing Event for 101 Training Mentors</li></ul>`,
                "image": uc
            },
            {
                "company": "TRUST",
                "title": "Mentor",
                "period": "2019 - 2021",
                "location": "Surabaya",
                "description": `<ul><li>Organizing Event for TRUST events on campuses</li></ul>`,
                "image": trust
            },
        ],
        "educations": [
            {
                "campus": "Ciputra University",
                "year": "2017-2021",
                "major": "Psychology",
                "gpa": "3.67/4.00",
                "image": uc
            }
        ],
        "portofolio": [
            {
                "name": "Instagram",
                "url": "https://instagram.com/leolaban99"
            }
        ]
    },
    {
        "id": 3,
        "name": "Darlene",
        "full_name": "Darlene Nathania",
        "about": "Graduated from Prasetiya Mulya University with 2 years of experience on tutoring various classes as well as teaching English and Character Development classes. Currently serving full-time in the church and teaching Character Development classes at Pilar Bangsa Junior High School and has a speciality on conversational English.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/10_wtqosz.png',
        "speciality": "English speaking, Arranging & Composing Music",
        "experiences": [
            {
                "company": "Pilar Bangsa Junior High School",
                "title": "Character Development Teacher",
                "period": "2024 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Guiding and teaching junoor high students on how to have good character</li></ul>`,
                "image": pilarbangsa
            },
            {
                "company": "TRUST Jakarta",
                "title": "Student Counselor",
                "period": "2021 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Providing mentorship and counselling to students</li><li>Teaching english classes (2021-2024)</li></ul>`,
                "image": trust
            },
            {
                "company": "Phoenix Orchestra",
                "title": "Secretary & First Violinist",
                "period": "2019 - 2020",
                "location": "Tangerang",
                "description": ``,
                "image": phoenix
            },
            {
                "company": "Private Tutoring",
                "title": "Tutor",
                "period": "2017 - 2018",
                "location": "Surabaya",
                "description": ``,
                "image": ""
            },
        ],
        "educations": [
            {
                "campus": "Universitas Prasetiya Mulya",
                "year": "2019 - 2022",
                "major": "Food Technology",
                "gpa": "3.93/4.0",
                "image": prasmul
            }
        ],
        "portofolio": [
            {
                "name": "Instagram",
                "url": "https://instagram.com/darlenenathania"
            }
        ]
    },
    {
        "id": 4,
        "name": "Monica",
        "full_name": "Monica Eliezer",
        "about": "Graduated from Prasetiya Mulya University with 2 years of experience on tutoring various classes as well as teaching English and Character Development classes. Currently serving full-time in the church and teaching Character Development classes at Pilar Bangsa Junior High School and has a speciality on conversational English.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019469/trust/mentors/9_hzudbq.png',
        "speciality": "Interior Design, Self Development, Freelancing",
        "experiences": [
            {
                "company": "Madera Home",
                "title": "Account Executive",
                "period": "November 2023 - Now",
                "location": "Tangerang",
                "description": `<ul><li>Estimating furniture budgetting plan</li><li>Monitoring Team Design</li><li>Perform Procurement (Purchasing Material/Accessories for production)</li><li>Approaching Material Vendor for Collaboration</li></ul>`,
                "image": madera
            },
            {
                "company": "Wooden Fabric",
                "title": "Account Executive",
                "period": "2021 - 2023",
                "location": "Tangerang",
                "description": `<ul><li>Estimating furniture budgetting plan</li><li>Monitoring Team Design</li><li>Perform Procurement (Purchasing Material/Accessories for production)</li><li>Approaching Material Vendor for Collaboration & dealing with client</li><li>Designing concept, 3D</li></ul>`,
                "image": wf
            },
            {
                "company": "Local Design Studio",
                "title": "Principal Design",
                "period": "2019 - Now",
                "location": "Tangerang",
                "description": `<ul><li>Creating concept, 3D, and Technical Drawing</li><li>Perform meeting with vendors and clients</li><li>Estimating budgetting plan</li></ul>`,
                "image": local
            },
        ],
        "educations": [
            {
                "campus": "Universitas Pelita Harapan",
                "year": "2015 - 2019",
                "major": "Interior Design",
                "gpa": "3.1/4.0",
                "image": uph
            }
        ],
        "portofolio": [
            {
                "name": "Web",
                "url": "https://thelocaldstudio.com"
            },
            {
                "name": "Instagram",
                "url": "https://instagram.com/thelocaldstudio"
            },
        ]
    },
    {
        "id": 7,
        "name": "Michelle",
        "full_name": "Michelle Angelina",
        "about": "Graduated from Universitas Kristen Petra at 2021, I am now helping young students voluntarily which I believe young people can make meaningful impacts. Currently serving fulltime as Counselor at TRUST Jakarta.",
        "image": 'https://res.cloudinary.com/internkuy/image/upload/v1723019467/trust/mentors/14_p0iapa.png',
        "speciality": "Mentorship, Music, Goal Setting, Video Editing",
        "experiences": [
            {
                "company": "TRUST",
                "title": "Student Counselor",
                "period": "2023 - Now",
                "location": "Jakarta",
                "description": `<ul><li>Mentoring students and helping them to have healthy community</li><li>Running Social Media and perform photo & video editing</li></ul>`,
                "image": trust
            },
            {
                "company": "Pilar Bangsa",
                "title": "Character Development Teacher",
                "period": "2023 - 2024",
                "location": "Jakarta",
                "description": ``,
                "image": pilarbangsa
            },
        ],
        "educations": [
            {
                "campus": "Universitas Kristen Petra",
                "year": "2017 - 2021",
                "major": "Business Accounting",
                "gpa": "3.84/4.0",
                "image": pcu
            }
        ],
        "portofolio": [
            {
                "name": "Instagram",
                "url": "https://instagram.com/michelleangelina"
            },
        ]
    }
]