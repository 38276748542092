import { CaretLeft, Scroll } from "@phosphor-icons/react";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppWrapper } from "../../global/style";
import { sendSlackNotification } from "../../services/slack";
import { BackWrapper } from "../events";
import { MentorHeaderIcon } from "../mentor/style";
import { CoursesData } from "./data";
import { BottomSheet, BottomSheetAttendance, BottomSheetContainer, CourseBody, CourseHeader, CourseWrapper, MentorDetailFlex, MentorFlex } from "./style";

const SLACK_COURSE_WEBHOOK = 'https://hooks.slack.com/services/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';

export const CoursePage = () => {
    const { id } = useParams();
    const [obj, setObj] = useState({});
    const [isMore, setIsMore] = useState(false);
    const [user, setUser] = useState({});
    const [isJoining, setIsJoining] = useState(false);
    const navigate = useNavigate();

    const fetchClass = () => {
        const course = CoursesData.find(item => item.id === Number(id));
        const profile = localStorage.getItem("profile");
        setObj(course);
        let userProfile = JSON.parse(profile);
        setUser(userProfile);
        let exists = userProfile.courses.find(item => item.id === Number(id));
        if (exists) {
            setIsJoining(true);
        }
    }

    useEffect(() => {
        fetchClass();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppWrapper>
            <CourseHeader>

                <BackWrapper>
                    <MentorHeaderIcon>
                        <CaretLeft size={15} onClick={() => navigate("/")} />
                    </MentorHeaderIcon>
                </BackWrapper>
                <img src={obj.image} alt={obj.name} />
            </CourseHeader>
            <CourseWrapper>
                <h1>{obj.name}</h1>
                <MentorFlex>
                    <MentorDetailFlex>
                        <img src={obj.mentor_image} alt={obj.mentor} />
                        <span>{obj.mentor}</span>
                    </MentorDetailFlex>
                    <MentorDetailFlex>
                        <Scroll size={22} />
                        <p>{obj.lessons} Lessons</p>
                    </MentorDetailFlex>
                </MentorFlex>
                <CourseBody>
                    {!isMore && <>
                        {obj.short_description && parse(obj.short_description)} <span onClick={() => setIsMore(true)}>Read More</span>
                    </>}
                    {isMore && <>
                        {obj.description && parse(obj.description)} <span onClick={() => setIsMore(false)}>Read Less</span>
                    </>}
                </CourseBody>
            </CourseWrapper>
            <BottomSheet>
                <BottomSheetContainer>
                    {isJoining ? <BottomSheetAttendance>
                        <p>You have joined this course</p>
                        <span>Attendance</span>
                    </BottomSheetAttendance> : <span onClick={() => {
                        const response = window.confirm(`Are you sure you want to join ${obj.name} with kak ${obj.mentor}?`);
                        if (response) {
                            user.courses.push(obj);
                            localStorage.setItem("profile", JSON.stringify(user));
                            setIsJoining(true);
                            let message = `*Course Member*\n\n*Name*\n${user.name}\n*Campus*\n${user.campus}\n*Phone*\n${user.phone}\n*Course*\n${obj.name}`
                            sendSlackNotification(message, SLACK_COURSE_WEBHOOK);
                        }
                    }
                    }>Join</span>}
                </BottomSheetContainer>
            </BottomSheet>
        </AppWrapper>
    );
}