import styled from "styled-components";

export const ModalWrapper = styled.div`
    padding: 1rem;
`;

export const FormWrapper = styled.form`
    & > input {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 8px 14px;
        margin-bottom: 8px;
        outline: none;
        font-size: 14px;
    }

    & >textarea {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 8px 14px;
        font-size: 14px;
        min-height: 150px;
        outline: 0;
    }

    & > h1 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
    }

    & > p {
        margin: 4px 0 1rem;
        font-size: 13px;
    }

    & > span {
        font-size: 14px;
        margin-bottom: 7px;
        display: inline-block;
    }
`;