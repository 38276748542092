import React, { useState } from "react";
import { StartedButton } from "../../pages/started/style";
import { sendSlackNotification } from "../../services/slack";
import { FormWrapper, ModalWrapper } from "../started/style";

const SLACK_ATTENDANCE = 'https://hooks.slack.com/services/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';

export const Attendance = ({ name, classname, code, point, type, id }) => {
    const [data, setData] = useState({
        code: ''
    })

    const onChange = ({ currentTarget: input }) => {
        data[input.name] = input.value;
        setData(data);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (code !== data['code']) {
            alert("Wrong attendance code!")
        } else {
            const profile = localStorage.getItem("profile");
            let userProfile = JSON.parse(profile);
            userProfile.points += point;
            if (type === 'courses') {
                let exists = userProfile.courses.find(item => item.id === Number(id));
                if (exists) {
                    exists.is_redeemed = 1;
                    exists.redeemed_at = Date.now();
                }
            } else {
                let exists = userProfile.events.find(item => item.id === Number(id));
                if (exists) {
                    exists.is_redeemed = 1;
                    exists.redeemed_at = Date.now();
                }
            }
            localStorage.setItem("profile", JSON.stringify(userProfile));
            window.location = window.location.href;

            let message = `*Attendance*\n\n*Name*\n${name}\n*Class*\n${classname}\n*Point*\n${point}`
            sendSlackNotification(message, SLACK_ATTENDANCE);

            alert(`Congratulations, you got ${point} points added to you!`)
        }
    }
    return (
        <ModalWrapper>
            <FormWrapper onSubmit={onSubmit}>
                <h1>Attendance</h1>
                <span>Show this screen to us! We will accept your attendancy.</span>
                <input placeholder="Attendance Code" name="code" onChange={onChange} />
                <StartedButton>
                    Submit
                </StartedButton>
            </FormWrapper>
        </ModalWrapper>
    )
}