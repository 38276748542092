import { Chat, Coins, Intersect } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppWrapper } from "../../global/style";
import { FormWrapper } from "../../modal/started/style";
import { sendSlackNotification } from "../../services/slack";
import { EventData } from "../events/data";
import { StartedButton } from "../started/style";
import { BadgeCard, BadgeGrid, ProfileHeader, ProfileWrapper, ProgressCard, ProgressWrapper, SectionCard, SectionGrid, SectionProfile } from "./style";

const SLACK_QNA_WEBHOOK = 'https://hooks.slack.com/services/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';

export const Profile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        courses: [],
        events: []
    });
    const [form, setForm] = useState({
        ask: ''
    })
    const [badges, setBadges] = useState([]);
    const [activeEvents, setActiveEvents] = useState([]);

    const onChange = ({ currentTarget: input }) => {
        form[input.name] = input.value;
        setForm(form);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (form['ask'] === '' || form['ask'].length < 10) {
            alert("Fill the question/suggestion correctly");
            return
        }

        let message = `*QNA*\n\n*From*\n${user.name}\n*Phone*\n${user.phone}\n*Questions/Suggestions*\n${form['ask']}`
        sendSlackNotification(message, SLACK_QNA_WEBHOOK)
        setTimeout(() => {
            window.location = window.location.href;
        }, 1000)
    }

    const fetchUser = () => {
        const profileStorage = localStorage.getItem("profile");
        let user = JSON.parse(profileStorage);
        setUser(user);
        const activeEvents = user.events.length > 0 ? user.events.filter(item => item.is_redeemed === 0) : [];
        setActiveEvents(activeEvents);
        if (user.events.length > 0) {
            const badges = [];
            user.events.forEach((item) => {
                if (item.is_redeemed === 1) {
                    const event = EventData.find(event => event.id === item.id);
                    badges.push(event.badge)
                }
            })
            setBadges(badges);
        }
    }

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AppWrapper>
            <ProfileWrapper>
                <h1>Profile</h1>
                <ProfileHeader>
                    <h1> {user.name}</h1>
                    <span>{user.campus} • {user.major}</span>
                    <p><Intersect /> Joined since {new Date(user.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                    })}</p>
                </ProfileHeader>
                <SectionProfile style={{ marginTop: "2rem" }}>
                    <h1>Overall Progress</h1>
                    <ProgressWrapper>
                        <ProgressCard>
                            <span>Total Joined Courses</span>
                            <h1>{user.courses.length}</h1>
                        </ProgressCard>
                        <ProgressCard>
                            <span>Total Joined Events</span>
                            <h1>{user.events.length}</h1>
                        </ProgressCard>
                    </ProgressWrapper>
                    <ProgressCard style={{ width: "100%", marginTop: "8px" }}>
                        <span>Points</span>
                        <h1>{user.points} <Coins color="#fec204" /></h1>
                    </ProgressCard>
                    <span>Collect points by joining our events and invite your friends</span>
                    <StartedButton style={{ marginTop: "1rem", fontSize: "16px" }} onClick={() => alert('Coming soon!')}>
                        Exchange my points
                    </StartedButton>
                </SectionProfile>
                <SectionProfile>
                    <h1>Your Badges</h1>
                    {badges.length === 0 ? (<span>None</span>) : <BadgeGrid>
                        {badges.map((item, index) => <BadgeCard key={index}>
                            <img src={item} alt="badge" />
                        </BadgeCard>)}
                    </BadgeGrid>}
                </SectionProfile>
                <SectionProfile>
                    <h1>Upcoming Events</h1>
                    {activeEvents.length === 0 ? (<span>You have no upcoming events yet</span>) : <SectionGrid>
                        {user.events.map((item, index) => <SectionCard key={index} onClick={() => navigate(`/events/${item['id']}`)}>
                            <img src={item.image} alt={item.name} style={{ height: "100%" }} />
                            <h1>{item.name}</h1>
                        </SectionCard>)}
                    </SectionGrid>}
                </SectionProfile>
                <SectionProfile>
                    <h1>Upcoming Courses</h1>
                    {user.courses.length === 0 ? (<span>You have no upcoming courses yet</span>) : <SectionGrid>
                        {user.courses.map((item, index) => <SectionCard key={index} onClick={() => navigate(`/courses/${item['id']}`)}>
                            <img src={item.image} alt={item.name} />
                            <h1>{item.name}</h1>
                        </SectionCard>)}
                    </SectionGrid>}
                </SectionProfile>
                <SectionProfile>
                    <h1 style={{ display: "flex", alignItems: "center", gap: "6px", marginBottom: "1rem" }}><Chat /> Ask us Anything</h1>
                    <FormWrapper onSubmit={onSubmit}>
                        <textarea name='ask' placeholder="Ask us whatever you want to ask. Request features for example, or report bugs" onChange={onChange} />
                        <StartedButton style={{ marginTop: "1rem" }}>
                            Submit
                        </StartedButton>
                    </FormWrapper>
                </SectionProfile>
            </ProfileWrapper>
        </AppWrapper>
    )
}