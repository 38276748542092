import styled from "styled-components";

export const HQCard = styled.a`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 260px;
    text-decoration: none;
    color: inherit;

    & > img {
        width: 260px;
        height: 150px;
        object-fit: cover;
        border-radius: 10px;
    }

    & > h1 {
        font-weight: 700;
        font-size: 16px;
        margin: 12px 0 2px;
    }
`;