import depok from '../../assets/image/hq/depok.jpeg';
import palmerah from '../../assets/image/hq/palmerah.webp';
import tandur from '../../assets/image/hq/tandur.jpeg';

export const HQData = [
    {
        title: "Sandang H2",
        campus: "Binus Kemanggisan",
        location: "Palmerah, Jakarta Barat",
        image: palmerah,
        url: "https://maps.app.goo.gl/8kqSYpQR7pTXPbDf9"
    },
    {
        title: "Rhema Grogol",
        campus: "Untar, Trisakti, TSM, Ukrida, Esgul",
        location: "Grogol",
        image: tandur,
        url: "https://maps.app.goo.gl/fQjCvJMn7vWRMqts5"
    },
    {
        title: "Rhema Kost",
        campus: "Universitas Indonesia",
        location: "Depok",
        image: depok,
        url: "https://maps.app.goo.gl/faojKzExiTxaCc6N7"
    }
]