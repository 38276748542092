import randomstring from 'randomstring';
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../component/context";
import { StartedButton } from "../../pages/started/style";
import { sendSlackNotification } from "../../services/slack";
import { FormWrapper, ModalWrapper } from "./style";

const SLACK_USER_WEBHOOK = 'https://hooks.slack.com/services/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';

export const StartedModal = () => {
    const { setFormData } = useContext(DataContext);
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: '',
        phone: '',
        major: '',
        campus: ''
    });

    const onChange = ({ currentTarget: input }) => {
        form[input.name] = input.value;
        setForm(form);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (form.name.length < 2) {
            alert("Fill your name correctly");
            return;
        }

        if (form.phone === "" || form.phone.length < 5) {
            alert("Fill the phone number correctly");
            return;
        }

        if (form.campus === "Universitas" || form.campus === "universitas") {
            alert("Fill the campus correctly");
            return;
        }

        if (form.campus === "" || form.campus.length < 3) {
            alert("Fill the campus correctly")
            return;
        }

        if (form.major === "" || form.major.length < 3) {
            alert("Fill your major correctly")
            return;
        }
        let body = form;
        body.created_at = Date.now();
        body.points = 0;
        body.events = [];
        body.courses = [];
        let randomStr = randomstring.generate(5);
        body.code = randomStr;
        setFormData(form);
        localStorage.setItem('profile', JSON.stringify(body));

        let message = `*New User Joined*\n\n*Name*\n${body.name}\n*Phone*\n${body.phone}\n*Campus*\n${body.campus}\n*Major*\n${body.major}`
        sendSlackNotification(message, SLACK_USER_WEBHOOK);
        navigate('/home')
    }

    return (
        <ModalWrapper>
            <FormWrapper onSubmit={onSubmit}>
                <h1>Begin your journey</h1>
                <input placeholder="Full Name" onChange={onChange} name="name" />
                <input placeholder="Phone Number" onChange={onChange} name="phone" />
                <input placeholder="Campus - Batch. eg: Binus - 2028" onChange={onChange} name="campus" />
                <input placeholder="Major, e.g: Computer Science" onChange={onChange} name="major" />
                <p>Your data will be used solely for the purpose of recording and tracking your activity within the apps. Not for marketing purpose.</p>
                <StartedButton>
                    Let's Go
                </StartedButton>
            </FormWrapper>
        </ModalWrapper>
    )
}