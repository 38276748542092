import styled from "styled-components";

export const MentorWrapper = styled.div`
    padding: 2rem 1.25rem
`;

export const MentorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
        font-size: 18px;
        margin: 0;
        font-weight: 700;
    }
`;

export const MentorHeaderIcon = styled.div`
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`;

export const MentorAvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
`;

export const MentorAvatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
        font-size: 18px;
        margin: 14px 0 2px;
        font-weight: 700;
    }

    & > span {
        font-size: 13px;
        text-align: center;
        max-width: 210px;
    }
`;

export const MentorAvatarImg = styled.div`
    position: relative;

    & > img {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
`;

export const MentorSocialMedia = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 8px;
`;

export const SocMed = styled.a`
    background-color: #fbfbfb;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-radius: 12px;
`;

export const MentorLabel = styled.div`
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
    margin: 0 12px;
    background-color: #7013ff;
    color: #fff;
    padding: 3px 8px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
`;

export const MentorBody = styled.div`
    margin: 1rem 0;
`;

export const MentorBodySection = styled.div`
    margin: 0 0 1.5rem;
    & > h1 {
        font-size: 18px;
        font-weight: 700;
    }

    & > p {
        line-height: 1.5;
    }
`;

export const ExperienceWrapper = styled.div`
    background-color: #fff;
    padding: 1rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ExperienceCard = styled.div`
`;

export const ExperienceCardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ExperienceCompany = styled.div`
    background-color: #fbfbfb;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    & > img {
        width: 50px;
    }
`;

export const ExperienceHeaderDetail = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 4px;
    }

    & > span {
        font-size: 13px;
    }
`;

export const ExperienceDesc = styled.div`
    margin-top: 8px;

    & > ul {
        margin: 0;

        & > li {
            font-size: 15px;
        }
    }

    & > span {
        font-size: 15px;
    }
`;