import React from "react";
import { CiViewList } from 'react-icons/ci';
import { useNavigate } from "react-router-dom";
import { CoursesData } from "../../pages/class/data";
import { CourseFooterFlex, CoursesCard, CoursesGrid, CoursesScroll, CoursesWrapper, LabelWrapper, LessonFlex, MentorCourseFlex } from "./style";

export const Course = () => {
    const navigate = useNavigate();
    return (
        <CoursesWrapper>
            <h1>Campus 101 Classes</h1>
            <CoursesScroll>
                <CoursesGrid>
                    {CoursesData.map((item, index) => <CoursesCard key={index} onClick={() => navigate(`/courses/${item.id}`)}>
                        <LabelWrapper>
                            <span>{item.label}</span>
                        </LabelWrapper>
                        <img src={item.image} alt="courses" />
                        <h1>{item.name}</h1>
                        <CourseFooterFlex>
                            <MentorCourseFlex>
                                <img src={item.mentor_image} alt="darlene" />
                                <span>{item.mentor}</span>
                            </MentorCourseFlex>
                            <LessonFlex>
                                <CiViewList />
                                <span>{item.lessons} Lessons</span>
                            </LessonFlex>
                        </CourseFooterFlex>
                    </CoursesCard>)}
                </CoursesGrid>
            </CoursesScroll>
        </CoursesWrapper>
    )
}