import styled from "styled-components";

export const CoursesScroll = styled.div`
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const CoursesWrapper = styled.div`
    margin-top: 2rem;

    & > h1 {
        font-size: 21px;
        font-weight: 800;
    }
`

export const CoursesGrid = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
`;

export const LabelWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 4px 8px;
    background-color: #3d4cdf;
    border-radius: 8px;

    & > span {
        color: #fff;
        font-size: 14px;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 4px;
    }
`;

export const CoursesCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 280px;

    & > img {
        width: 280px;
        height: 180px;
        object-fit: cover;
        border-radius: 10px;
    }

    & > h1 {
        font-weight: 700;
        font-size: 16px;
        margin: 12px 0 2px;
    }

`;

export const MentorCourseFlex = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    & > img {
        width: 30px;
        border-radius: 50%;
        border: 1px solid #ddd;
    }

    & > span {
        font-size: 13px;
        font-weight: 600;
        margin: 0;
    }
`;

export const CourseFooterFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
`;

export const LessonFlex = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;

    & > span {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
    }
`;
