import badge from '../../assets/image/badge/event01.png';
import img01 from '../../assets/image/events/001.webp';


export const EventData = [
    {
        id: 1,
        name: "Freshmen Kickoff: Games and Goals",
        image: img01,
        time: '17.00 - Selesai',
        point: 10,
        code: 'KICKOFF101',
        badge: badge,
        description: `<p>Welcome to your first year at college! Join us for "Freshmen Kickoff: Games and Goals," an exciting event designed to help you navigate your freshman year with confidence. Engage in fun games, connect with fellow freshmen, and learn essential time management and goal-setting skills. Get ready to set yourself up for success both academically and socially. Don't miss this chance to start your college journey on the right foot!</p>`,
        active: 0
    },
    {
        id: 2,
        name: "Barbeque: Free Meals, Drinks, Games, and Sharing",
        image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019944/trust/bbq_kuvcys.png',
        time: '18.00 - End',
        point: 10,
        code: 'RIBEYE',
        badge: 'https://res.cloudinary.com/internkuy/image/upload/v1723019139/trust/C_2_qdl02j.png',
        description: `<p>Get ready for an unforgettable evening of fun, food, and friends! We are excited to invite you to our BBQ Party on Friday, August 9, 2024, at the charming Sister House Binus. The festivities will kick off at 6:00 PM and continue until we drop!</p>
<p>Join us for a delightful evening where you'll enjoy a mouth-watering BBQ feast, including a variety of grilled meats, delicious sides, and refreshing beverages. This is the perfect opportunity to unwind, socialize, and make lasting memories with friends and family.</p>`,
        active: 0
    }
]