import styled from "styled-components";

export const CategoryWrapper = styled.div`
    & > h1 {
        font-size: 21px;
        font-weight: 800;
    }
    margin-bottom: 2rem;
`;

export const CategoryOverflow = styled.div`
    overflow: scroll;

    &::-webkit-scrollbar{ 
        display: none;
    }
`;

export const CategoryGrid = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
`;

export const CategoryCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 70px;
    align-items: center;

    & > span {
        color: #858c9b;
        font-size: 13px;
        font-weight: 500;
    }
`;

export const IconWrapper = styled.div`
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;

    & > img {
        width: 40px;
    }
`;

export const CategoryContent = styled.div`
    display: flex;
`;