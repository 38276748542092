import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Red Hat Display', sans-serif;
        margin: 0;
        padding: 0;
        /* letter-spacing: -.009em; */
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    p {
        margin: 0;
    }
`;