
import english from '../../assets/image/courses/001.webp';
import interior from '../../assets/image/courses/002.webp';
import accounting from '../../assets/image/courses/003.jpg';
import financial from '../../assets/image/courses/004.jpg';
import webdev from '../../assets/image/courses/005.jpg';
import bible from '../../assets/image/courses/006.jpg';

export const CoursesData = [
    {
        id: 1,
        name: "Complete Guide to Better English: Conversational",
        label: "English",
        mentor: "Darlene",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/10_wtqosz.png',
        image: english,
        lessons: "12",
        short_description: `<p>Welcome to the "Complete Guide to Better English: Conversational" course, tailored for college students who want to improve their spoken English. This course covers essential conversational techniques ...</p>`,
        description: `<p>Welcome to the "Complete Guide to Better English: Conversational" course, tailored for college students who want to improve their spoken English.</p><p>This course covers essential conversational techniques, including listening, speaking, and pronunciation skills. You will engage in interactive exercises and real-life practice sessions, enhancing your confidence in various social and professional settings. By the end of the course, you'll be able to express yourself more clearly, participate effectively in discussions, and excel in interviews. Join us to transform your conversational abilities and succeed in your academic and social endeavors!</p> `
    },
    {
        id: 2,
        name: "The Complete Web Development 2024 Edition: HTML, CSS & JS",
        label: "Web Development",
        mentor: "Kevin",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019469/trust/mentors/8_ecke23.png',
        image: webdev,
        lessons: "12",
        short_description: `<p>Welcome to the "Web Development, HTML CSS JS 2024 Edition" course, designed for aspiring web developers who want to build modern and responsive websites. This comprehensive course covers the latest techniques and best practices in ...</p>`,
        description: `<p>Welcome to the "Web Development, HTML CSS JS 2024 Edition" course, designed for aspiring web developers who want to build modern and responsive websites. This comprehensive course covers the latest techniques and best practices in HTML, CSS, and JavaScript, ensuring you stay ahead in the fast-evolving world of web development.</p><p>Through hands-on projects and interactive lessons, you will learn how to create visually appealing and user-friendly websites from scratch. By the end of the course, you will have the skills to develop professional-grade web applications, understand responsive design principles, and implement dynamic functionality using JavaScript. Join us to kickstart your web development journey and build a strong foundation for your future career!</p>`
    },
    {
        id: 3,
        name: "Complete Introduction to Accounting and Finance",
        label: "Accounting",
        mentor: "Yehezkiel",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019470/trust/mentors/7_jofiub.png',
        image: accounting,
        lessons: "12",
        short_description: `<p>Unlock the fundamentals of accounting and finance with our comprehensive introductory course designed specifically for college students. This course provides a solid foundation in financial principles, essential accounting practices ...</p>`,
        description: `<p>Unlock the fundamentals of accounting and finance with our comprehensive introductory course designed specifically for college students. This course provides a solid foundation in financial principles, essential accounting practices, and the critical skills needed to manage financial information effectively. You'll explore key topics such as financial statement analysis, budgeting, cost accounting, and financial management.</p><p>Perfect for students new to the subject or those looking to strengthen their understanding, this course will equip you with the knowledge and skills to excel in both academic and professional settings. Start your journey towards financial expertise today!</p>`
    },
    {
        id: 4,
        name: "Grow Personal Financial Security Thinking & Principles",
        label: "Financial",
        mentor: "Reinhard",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/11_grkxjc.png',
        image: financial,
        lessons: "12",
        short_description: `<p>Enhance your financial future with our course, "Grow Personal Financial Security Thinking & Principles," designed to empower college students with the knowledge and skills to achieve financial security. This course covers essential principles of personal finance, including budgeting, saving ...</p>`,
        description: `<p>Enhance your financial future with our course, "Grow Personal Financial Security Thinking & Principles," designed to empower college students with the knowledge and skills to achieve financial security. This course covers essential principles of personal finance, including budgeting, saving, investing, debt management, and wealth management.<p></p>You'll learn how to set financial goals, create effective financial plans, and make informed decisions to secure your financial well-being. Ideal for students eager to build a strong financial foundation, this course will guide you in developing the mindset and strategies necessary for long-term financial success. Start taking control of your financial future today!</p>`
    },
    {
        id: 5,
        name: "Basic Interior Design: Bring your Ideas with SketchUp",
        label: "Interior",
        mentor: "Monica",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019469/trust/mentors/9_hzudbq.png',
        image: interior,
        lessons: "12",
        short_description: `<p>Unleash your creativity with our course, "Basic Interior Design with SketchUp," tailored for college students eager to explore the fundamentals of interior design. This course introduces you to ...</p>`,
        description: `<p>Unleash your creativity with our course, "Basic Interior Design with SketchUp," tailored for college students eager to explore the fundamentals of interior design. This course introduces you to essential design principles and teaches you how to bring your ideas to life using SketchUp, a leading design software.</p><p>You'll learn space planning, color theory, and furniture selection while gaining hands-on experience in creating detailed floor plans and 3D models. Perfect for beginners, this course will equip you with the skills and confidence to embark on a career in interior design. Start transforming spaces with your unique vision today!</p>`
    },
    {
        id: 6,
        name: "Bible Study with Kefas: Knowing the Purpose of Our Life",
        label: "Bible Study",
        mentor: "Oki",
        mentor_image: 'https://res.cloudinary.com/internkuy/image/upload/v1723019468/trust/mentors/12_ouia3i.png',
        image: bible,
        lessons: "12",
        short_description: `<p>Embark on a transformative journey with our course, "Bible Study with Kefas: Knowing the Purpose of Our Life," designed to help college students discover the deeper meaning of their existence through biblical ...</p>`,
        description: `<p>Embark on a transformative journey with our course, "Bible Study with Kefas: Knowing the Purpose of Our Life," designed to help college students discover the deeper meaning of their existence through biblical teachings. Led by Kefas, this course delves into key scriptures and themes that reveal God's purpose for our lives.</p><p>You'll engage in thoughtful discussions, reflective exercises, and practical applications to enhance your understanding of faith and purpose. Perfect for students seeking spiritual growth and clarity, this course will guide you in aligning your life with divine purpose. Join us and start uncovering the profound purpose of your life today!</p>`
    }
]