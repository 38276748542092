import styled from "styled-components";

export const EventsWrapper = styled.div`
    margin-top: 2rem;

    & > h1 {
        font-size: 21px;
        font-weight: 800;
    }
`;

export const EventsScroll = styled.div`
    overflow: scroll;

    &::-webkit-scrollbar{
        display: none;
    }
`;

export const EventGrid = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
`;

export const EventCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    & > img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 10px;
    }

    & > h1 {
        font-weight: 700;
        font-size: 16px;
        margin: 12px 0 2px;
    }

`;


export const EventLabel = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 8px;
    background-color: #25d075;
    border-radius: 8px;

    & > span {
        color: #fff;
        font-size: 13px;
        margin: 0;
        font-weight: 500;
    }
`;