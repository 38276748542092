import styled from "styled-components";

export const StartedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    position: relative;

    & > img {
        width: 300px;
    }
`;

export const BottomSheet = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
`;

export const BottomSheetWrapper = styled.div`
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;

    & > h1 {
        font-size: 26px;
        line-height: 1.1;
        font-weight: 800;
        margin-bottom: 12px;
    }

    & > p {
        line-height: 1.5;
        color: #636363;
        font-weight: 600;
        font-size: 17px;
    }
`;


export const StartedButton = styled.button`
    background-color: #000;
    height: 45px;
    border-radius: 8px;
    border: 0;
    outline: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    margin-top: 4rem;
    margin-bottom: 10px;
    font-weight: 500;
`;