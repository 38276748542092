import styled from "styled-components";

export const ProfileWrapper = styled.div`
    padding: 1.5rem;

    & > h1 {
        font-size: 28px;
        font-weight: 700;
    }
`;

export const ProfileHeader = styled.div`
    margin-top: 2rem;
    /* background-color: #fff; */
    /* padding: 1rem; */
    /* border-radius: 12px; */
    
    & > h1 {
        font-size: 21px;
        margin-bottom: 0;
        font-weight: 600;
    }

    & > span {
        font-size: 15px;
    }

    & > p {
        margin-top: 1rem;
        display: flex;
        gap: 5px;
        align-items: center;
    }
`;

export const SectionProfile = styled.div`
    margin-top: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;

    & > h1 {
        font-size: 18px;
        font-weight: 700;
    }

    & > span {
        font-size: 14px;
        margin-top: 5px;
        display: inline-block;
    }
`;

export const ProgressWrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
`;

export const ProgressCard = styled.div`
    width: 50%;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 14px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    & > h1 {
        font-size: 28px;
        font-weight: 700;
        margin: 0;
    }

    & > span {
        line-height: 1.1;
    }
`;

export const BadgeGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    margin-top: 1rem;
`;

export const BadgeCard = styled.div`
    & > img {
        width: 100%;
    }
`;

export const SectionGrid = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
`;

export const SectionCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    & > img {
        width: 100%;
        object-fit: cover;
        height: 150px;
        border-radius: 12px;
    }
    
    & > h1 {
        font-size: 16px;
        margin-top: 8px;
    }
`;

export const BadgeWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;

    & > img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
`;