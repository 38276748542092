import styled from "styled-components";

export const MentorsScroll = styled.div`
    overflow: scroll;

     &::-webkit-scrollbar {
        display: none;
    }

`;

export const MentorsWrapper = styled.div`
   
    & > h1 {
        font-size: 21px;
        font-weight: 800;
    }
`;


export const MentorsGrid = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 1rem;
`;

export const MentorCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    min-width: 70px;

    & > img {
        border-radius: 50%;
        width: 75px;
        height: 75px;
        object-fit: cover;
    }

    & > span {
        font-size: 14px;
        font-weight: 600;
    }
`;