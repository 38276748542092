import { BookOpen, Calculator, Desktop, HandCoins, PaintBrushHousehold, Translate } from "@phosphor-icons/react";
import React from "react";
import { CategoryCard, CategoryGrid, CategoryOverflow, CategoryWrapper, IconWrapper } from "./style";

export const Category = () => {
    return (
        <CategoryWrapper>
            <h1>Explore Topics</h1>
            <CategoryOverflow>
                <CategoryGrid>
                    <CategoryCard>
                        <IconWrapper>
                            <Translate size={35} />
                        </IconWrapper>
                        <span>Language</span>
                    </CategoryCard>
                    <CategoryCard>
                        <IconWrapper>
                            <BookOpen size={35} />
                        </IconWrapper>
                        <span>Bible Study</span>
                    </CategoryCard>
                    <CategoryCard>
                        <IconWrapper>
                            <Desktop size={35} />
                        </IconWrapper>
                        <span>Web/Apps</span>
                    </CategoryCard>
                    <CategoryCard>
                        <IconWrapper>
                            <PaintBrushHousehold size={35} />
                        </IconWrapper>
                        <span>Interior</span>
                    </CategoryCard>
                    <CategoryCard>
                        <IconWrapper>
                            <HandCoins size={35} />
                        </IconWrapper>
                        <span>Financial</span>
                    </CategoryCard>
                    <CategoryCard>
                        <IconWrapper>
                            <Calculator size={35} />
                        </IconWrapper>
                        <span>Accounting</span>
                    </CategoryCard>
                </CategoryGrid>
            </CategoryOverflow>
        </CategoryWrapper>
    )
}