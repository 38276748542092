import { MapPinSimple } from "@phosphor-icons/react";
import React from "react";
import { LabelWrapper } from "../course/style";
import { MentorsGrid, MentorsScroll, MentorsWrapper } from "../mentor/style";
import { HQData } from "./data";
import { HQCard } from "./style";

export const HQ = () => {
    return (
        <MentorsWrapper style={{ marginTop: "2rem" }}>
            <h1>Find Us</h1>
            <MentorsScroll>
                <MentorsGrid>
                    {HQData.map((item, index) => <HQCard key={index} href={item.url} target="_blank" rel="noreferrer">
                        <LabelWrapper>
                            <span><MapPinSimple /> {item.location}</span>
                        </LabelWrapper>
                        <img src={item.image} alt="a" />
                        <h1>{item.title}</h1>
                        <span>{item.campus}</span>
                    </HQCard>)}
                </MentorsGrid>
            </MentorsScroll>
        </MentorsWrapper>
    )
}