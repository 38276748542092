import styled from "styled-components";

export const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  max-width: 500px;
  position: relative;
  background-color: #f6f6f6;
`;

export const SectionWrapper = styled.div`
    padding: 1rem;
    position: relative;
`;