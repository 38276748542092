import { CaretLeft, DotsThreeVertical, InstagramLogo, LinkedinLogo, Planet, TiktokLogo } from "@phosphor-icons/react";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppWrapper } from "../../global/style";
import { MentorData } from "./data";
import { ExperienceCard, ExperienceCardHeader, ExperienceCompany, ExperienceDesc, ExperienceHeaderDetail, ExperienceWrapper, MentorAvatar, MentorAvatarImg, MentorAvatarWrapper, MentorBody, MentorBodySection, MentorHeader, MentorHeaderIcon, MentorLabel, MentorSocialMedia, MentorWrapper, SocMed } from "./style";

export const MentorDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [mentor, setMentor] = useState({});

    const fetchMentor = () => {
        const mentor = MentorData.find(item => item.id === Number(id));
        setMentor(mentor)
    }

    useEffect(() => {
        fetchMentor();
    })

    return (
        <AppWrapper>
            <MentorWrapper>
                <MentorHeader>
                    <MentorHeaderIcon>
                        <CaretLeft size={15} onClick={() => navigate("/")} />
                    </MentorHeaderIcon>
                    <h1>Mentor Profile</h1>
                    <MentorHeaderIcon>
                        <DotsThreeVertical size={15} />
                    </MentorHeaderIcon>
                </MentorHeader>
                <MentorAvatarWrapper>
                    <MentorAvatar>
                        <MentorAvatarImg>
                            <img src={mentor.image} alt={mentor.name} />
                            <MentorLabel>
                                Mentor
                            </MentorLabel>
                        </MentorAvatarImg>
                        <h1>{mentor['full_name']}</h1>
                        <span>{mentor.speciality}</span>
                        <MentorSocialMedia>
                            {mentor.portofolio && mentor.portofolio.map((item, index) => <SocMed key={index} href={item.url}>
                                {item.name === "Web" ? (<Planet size={20} />) : item.name === "Instagram" ? (<InstagramLogo size={20} />) : item.name === "Linkedin" ? (<LinkedinLogo size={20} />) : item.name === "Tiktok" ? (<TiktokLogo size={20} />) : null}
                            </SocMed>)}
                        </MentorSocialMedia>
                    </MentorAvatar>
                </MentorAvatarWrapper>
                <MentorBody>
                    <MentorBodySection>
                        <h1>About</h1>
                        <p>{mentor.about}</p>
                    </MentorBodySection>
                    <MentorBodySection>
                        <h1>Experiences</h1>
                        <ExperienceWrapper>
                            {mentor.experiences && mentor.experiences.map((item, index) => <ExperienceCard style={{ borderBottom: index === mentor.experiences.length - 1 ? '0' : '1px solid #eee', paddingBottom: index === mentor.experiences.length - 1 ? '0' : '1rem' }} key={index}>
                                <ExperienceCardHeader>
                                    <ExperienceCompany>
                                        {item.image && <img src={item.image} alt={item.company} />}
                                    </ExperienceCompany>
                                    <ExperienceHeaderDetail>
                                        <h1>{item.title} at {item.company}</h1>
                                        <span>{item.period} • {item.location}</span>
                                    </ExperienceHeaderDetail>
                                </ExperienceCardHeader>
                                <ExperienceDesc>
                                    {parse(item.description)}
                                </ExperienceDesc>
                            </ExperienceCard>)}
                        </ExperienceWrapper>
                    </MentorBodySection>
                    <MentorBodySection>
                        <h1>Education</h1>
                        <ExperienceWrapper>
                            {mentor.educations && mentor.educations.map((item, index) => <ExperienceCard key={index}>
                                <ExperienceCardHeader>
                                    <ExperienceCompany>
                                        <img src={item.image} alt={item.campus} />
                                    </ExperienceCompany>
                                    <ExperienceHeaderDetail>
                                        <h1>{item.major} at {item.campus}</h1>
                                        <span>{item.year}</span>
                                    </ExperienceHeaderDetail>
                                </ExperienceCardHeader>
                                <ExperienceDesc>
                                    <span>GPA: {item.gpa}</span>
                                </ExperienceDesc>
                            </ExperienceCard>)}
                        </ExperienceWrapper>
                    </MentorBodySection>
                </MentorBody>
            </MentorWrapper>
        </AppWrapper>
    )
}