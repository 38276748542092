import { UserCircle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import person from '../../assets/image/person.png';
import { AppWrapper } from "../../global/style";
import { Category } from "../../section/categories";
import { Course } from "../../section/course";
import { EventSection } from "../../section/events";
import { HQ } from "../../section/hq";
import { Mentor } from "../../section/mentor";
import { HeaderFlex, HeroBanner, HeroContent, HomeHeader, HomeHeaderLeft, HomeWrapper, ImageHero } from "./style";

export const HomePage = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        let localProfile = localStorage.getItem('profile')
        setData(JSON.parse(localProfile))
    }, []);

    return (
        <AppWrapper style={{ backgroundColor: "#fbfbfb" }}>
            <HomeHeader>
                <HeaderFlex>
                    <HomeHeaderLeft>
                        <h1>Hi, Welcome 👋🏻</h1>
                        <p>{data.name}</p>
                    </HomeHeaderLeft>
                    <UserCircle size={35} color="#fff" onClick={() => window.location = '/profile'} style={{ zIndex: "999" }} />
                </HeaderFlex>
                <HeroBanner>
                    <HeroContent>
                        <h1>Unleash Your Potential</h1>
                        <p>Collect Points and <br />Exchange with Prizes.</p>
                        <span>FYP Only</span>
                    </HeroContent>
                    <ImageHero>
                        <img src={person} alt="person" />
                    </ImageHero>
                </HeroBanner>
            </HomeHeader>
            <HomeWrapper>
                <Category />
                <Mentor />
                <Course />
                <EventSection />
                <HQ />
            </HomeWrapper>
        </AppWrapper>
    )
}