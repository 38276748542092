import React from "react";
import { useNavigate } from "react-router-dom";
import { MentorData } from "../../pages/mentor/data";
import { MentorCard, MentorsGrid, MentorsScroll, MentorsWrapper } from "./style";

export const Mentor = () => {
    const navigate = useNavigate();
    return (
        <MentorsWrapper>
            <h1>Our Mentors</h1>
            <MentorsScroll>
                <MentorsGrid>
                    {MentorData.map((item, index) => <MentorCard key={index} onClick={() => navigate(`/mentor/${item.id}`)}>
                        <img src={item.image} alt={item.name} />
                        <span>{item.name}</span>
                    </MentorCard>)}
                    {/* <MentorCard>
                        <img src={img01} alt="01" />
                        <span>Yehezkiel</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img006} alt="05" />
                        <span>Oktavianus</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img008} alt="03" />
                        <span>Leo</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img02} alt="02" />
                        <span>Kevin</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img05} alt="05" />
                        <span>Reinhard</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img007} alt="04" />
                        <span>Michelle</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img03} alt="03" />
                        <span>Monica</span>
                    </MentorCard>
                    <MentorCard>
                        <img src={img04} alt="04" />
                        <span>Darlene</span>
                    </MentorCard> */}
                </MentorsGrid>
            </MentorsScroll>
        </MentorsWrapper>
    )
}