import React, { useState } from "react";
import img from '../../assets/image/started.png';
import { ModalComponent } from "../../component/modal";
import { AppWrapper, SectionWrapper } from "../../global/style";
import { StartedModal } from "../../modal/started";
import { BottomSheet, BottomSheetWrapper, StartedButton, StartedContainer } from "./style";

export const StartedPage = () => {
    const [show, setShow] = useState(false);

    return (
        <AppWrapper>
            <SectionWrapper>
                <StartedContainer>
                    <img src={img} alt="started" />
                </StartedContainer>
            </SectionWrapper>
            <BottomSheet>
                <BottomSheetWrapper>
                    <h1>New way to start your perfect college life</h1>
                    <p>Discover free courses, mentorship, and healthy community with TRUST.</p>
                    <StartedButton onClick={() => setShow(true)}>
                        Get Started Now
                    </StartedButton>
                </BottomSheetWrapper>
            </BottomSheet>
            <ModalComponent
                show={show}
                onHide={() => {
                    setShow(false)
                }}
                body={<StartedModal />}
            />
        </AppWrapper>
    );
}