import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventData } from "../../pages/events/data";
import { EventCard, EventGrid, EventLabel, EventsScroll, EventsWrapper } from "./style";

export const EventSection = () => {
    const navigate = useNavigate();

    const [events, setEvents] = useState([]);

    const fetchEvents = () => {
        let activeEvents = EventData.filter(item => item.active === 1);
        setEvents(activeEvents);
    }

    useEffect(() => {
        fetchEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <EventsWrapper>
            <h1>Upcoming Events</h1>
            <EventsScroll>
                <EventGrid>
                    {events.length > 0 ? events.map((item, index) => <EventCard key={index} onClick={() => navigate(`/events/${item.id}`)}>
                        <EventLabel>
                            <span>{item.time}</span>
                        </EventLabel>
                        <img src={item.image} alt="event" />
                    </EventCard>) : <span>No active events yet</span>}
                </EventGrid>
            </EventsScroll>
        </EventsWrapper>
    )
}